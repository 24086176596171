import { http } from "@/http/http";
import app from '@/main';
import { mdiCheck } from "@mdi/js";
import { rowPerPage } from "@/constants/config.js";

const state = {
  inboxList: undefined,
  axiosLoading: false,
  singleMemo: undefined,
  selectAllInbox: false,
  singleMemoHistory: undefined,
  singleMemoSignerHistory: undefined,
  // inboxAlert: false,
  // inboxAlertMessage: undefined,
  dispositionDialog: false,
  dispositionSimpleDialog: false,
  dispositionHistoryDialog: false,
  permitHistoryDialog: false,
  printPreviewDialog: false,
  broadcastDialog: false,
  delegationHistory: null,
  dispositionHistory: null,
  dispositionHistoryDetail: null,
  memoValidationDialog: false,
  permitAnswerDialog: false,
  permitProcess: 'not-started',
  permitTokenIsValid: null,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    only: '',
    filter: '',
    from: 0,
    to: 0,
    lastPage: 1,
    total: 0
  }
};
const getters = {
  inboxList: state => state.inboxList,
  filterParams: state => state.filterParams,
  singleMemo: state => state.singleMemo,
  selectAllInbox: state => state.selectAllInbox,
  singleMemoHistory: state => state.singleMemoHistory,
  inboxAlert: state => state.inboxAlert,
  inboxAlertMessage: state => state.inboxAlertMessage,
  dispositionDialog: state => state.dispositionDialog,
  dispositionSimpleDialog: state => state.dispositionSimpleDialog,
  permitAnswerDialog: state => state.permitAnswerDialog,
  dispositionHistoryDialog: state => state.dispositionHistoryDialog,
  permitHistoryDialog: state => state.permitHistoryDialog,
  dispositionHistoryDetail: state => state.dispositionHistoryDetail,
  dispositionHistory: state => state.dispositionHistory,
  delegationHistory: state => state.delegationHistory,
  printPreviewDialog: state => state.printPreviewDialog,
  broadcastDialog: state => state.broadcastDialog,
  axiosLoading: state => state.axiosLoading,
};
const mutations = {
  SET_INBOX_LIST(state, payload) {
    state.inboxList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.filter = "";
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams.sortBy = payload.sortBy;
    state.filterParams.sortByDesc = payload.sortByDesc
    state.filterParams.perPage = payload.perPage;
    state.filterParams.currentPage = payload.currentPage;
    state.filterParams.lastPage = payload.lastPage;
    state.filterParams.filter = payload.filter;
    state.filterParams.only = payload.only;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload
  },
  SET_EMPTY_INBOX(state) {
    state.inboxList = undefined;
  },
  SET_SELECT_ALL_INBOX(state, payload) {
    state.selectAllInbox = payload;
  },
  SET_SINGLE_MEMO(state, payload) {
    state.singleMemo = payload;
  },
  SET_SINGLE_MEMO_HISTORY(state, payload) {
    state.singleMemoHistory = payload;
  },
  SET_SINGLE_MEMO_SIGNER_HISTORY(state, payload) {
    state.singleMemoSignerHistory = payload;
  },
  SET_DISPOSITION_HISTORY(state, payload) {
    state.dispositionHistory = payload;
  },
  SET_DISPOSITION_HISTORY_DETAIL(state, payload) {
    state.dispositionHistoryDetail = payload
  },
  SET_DELEGATION_HISTORY(state, payload) {
    state.delegationHistory = payload;
  },
  SET_INBOX_ALERT(state, payload) {
    if (payload === false) {
      state.inboxAlertMessage = undefined;
    }
    state.inboxAlert = payload;
  },
  SET_INBOX_ALERT_MESSAGE(state, payload) {
    state.inboxAlertMessage = payload;
  },
  SET_DISPOSITION_DIALOG(state, payload) {
    state.dispositionDialog = payload;
  },
  SET_DISPOSITION_SIMPLE_DIALOG(state, payload) {
    state.dispositionSimpleDialog = payload;
  },
  SET_PERMIT_ANSWER_DIALOG(state, payload) {
    state.permitAnswerDialog = payload;
  },
  SET_PERMIT_HISTORY_DIALOG(state, payload) {
    state.permitHistoryDialog = payload;
  },
  SET_DISPOSITION_HISTORY_DIALOG(state, payload) {
    state.dispositionHistoryDialog = payload;
  },
  SET_PRINT_PREVIEW_DIALOG(state, payload) {
    state.printPreviewDialog = payload;
  },
  SET_BROADCAST_DIALOG(state, payload) {
    state.broadcastDialog = payload;
  },
  SET_MEMO_VALIDATION_DIALOG(state, payload) {
    state.memoValidationDialog = payload;
  },
  SET_PERMIT_TOKEN_IS_VALID(state, payload) {
    state.permitTokenIsValid = payload;
  },
  SET_PERMIT_PROCESS(state, payload) {
    state.permitProcess = payload;
  }
};
const actions = {
  loadInbox({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/inbox?page=${payload.currentPage}&filter=${payload.filter}&only=${payload.only}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_INBOX_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  loadInboxXG({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/sentxg?page=${payload.currentPage}&filter=${payload.filter}&only=${payload.only}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_INBOX_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting",tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  loadIncoming({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/inbox/incoming?page=${payload.currentPage}&filter=${payload.filter}&only=${payload.only}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_INBOX_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);
          return resolve(response.data)
        })
    })
  },
  loadPermit({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/inbox/izin-prinsip?page=${payload.currentPage}&filter=${payload.filter}&only=${payload.only}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_INBOX_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  loadInboxDetail({ commit, dispatch }, payload) {
    commit("SET_SINGLE_MEMO", undefined);

    commit('SET_AXIOS_LOADING', true)

    return new Promise(resolve => {
      http.get(`/memo/inbox/${payload}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_SINGLE_MEMO", response.data);
          dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
        }
        setTimeout(() => {
          commit('SET_AXIOS_LOADING', false)
        }, 300);
        return resolve(response.data);
      });


    });
  },

  loadInboxHistory({ commit }, payload) {
    commit("SET_SINGLE_MEMO_HISTORY", undefined);

    return new Promise(resolve => {
      http.get(`/memo/${payload}/history/receiver`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_SINGLE_MEMO_HISTORY", response.data);
        }
        return resolve(response.data);
      });
    });
  },

  loadSignerHistory({ commit }, payload) {
    commit("SET_SINGLE_MEMO_SIGNER_HISTORY", undefined);
    //  api/memo/{id}/history-by-id
    return new Promise(resolve => {
      http.get(`/memo/${payload}/history-by-id`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_SINGLE_MEMO_SIGNER_HISTORY", response.data);
        }

        return resolve(response.data);
      });


    });
  },

  loadDispositionHistory({ commit }, payload) {
    commit("SET_SINGLE_MEMO_HISTORY", undefined);

    return new Promise(resolve => {
      http.get(`/memo/${payload}/history/receiver`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_SINGLE_MEMO_HISTORY", response.data);
        }

        return resolve(response.data);
      });

    });
  },

  submitMyDisposition({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.post(`/memo/${payload.memoId}/disposition`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('loadInboxDetail', payload.memoId);
            // dispatch('getDisposition', { memoId: payload.memoId, bgProcess: true, type: 'mydelegation' });

            app.$dialog.info({
              text: app.$t("msgbox.submit-disposition-to-downline-is-success"),
              title: app.$t("msgbox.submit-disposition"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },

  submitMyBroadcast({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true)
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/broadcast/${payload.memoId}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.broadcast-memo-is-success"),
              title: app.$t("msgbox.broadcast-memo"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },

  requestTelegramToken({ commit, dispatch }, payload) {
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.get(`/memo-izin-prinsip/${payload.permitId}/get-otp`, payload.formData)
        .then((response) => {
          if(response.status == 500) {
            dispatch('user/setCountDownTime', undefined, { root: true });
          }
          if (response && (parseInt(response.status == 200) || response.status == 'success')) {
            app.$dialog.notify.info(app.$t("msgbox.please-check-your-telegram-to-get-token"), {
              position: "top-left",
              timeout: 5000,
            });
          }
          setTimeout(() => {
            commit('SET_PERMIT_PROCESS', 'not-started')
          }, 500);
          return resolve(response);
        });
    });
  },

  submitMyAnswer({ commit, dispatch }, payload) {
    commit('SET_PERMIT_PROCESS', 'processing');
    //save, submit, share-to-revision, send, broadcast
    return new Promise((resolve) => {
      //submit is send to superior
      // api/memo/{id}/disposition
      http.post(`/memo-izin-prinsip/${payload.permitId}/answer`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            if (response.data == 'otp-token-invalid--or-token-expired') {
              commit('SET_PERMIT_TOKEN_IS_VALID', false);
              commit('SET_PERMIT_PROCESS', 'not-started');
              app.$dialog.error({
                text: app.$t("error.otp-token-invalid--or-token-expired"),
                title: app.$t("msgbox.submit-permit-answer"),
                persistent: true,
                actions: {
                  false: app.$t("button.ok")
                }
              });
            }
            else {
              dispatch('loadInboxDetail', payload.memoId);
              // dispatch('getDisposition', { memoId: payload.memoId, bgProcess: true, type: 'mydelegation' });
              app.$dialog.info({
                text: app.$t("msgbox.submit-permit-answer-is-success"),
                title: app.$t("msgbox.submit-permit-answer"),
                persistent: true,
                actions: {
                  false: app.$t("button.ok")
                }
              });
              dispatch('user/setCountDownTime', undefined, { root: true });
              commit('SET_PERMIT_PROCESS', 'success');
              commit('SET_PERMIT_TOKEN_IS_VALID', true);
            }

          }
          setTimeout(() => {
            commit('SET_PERMIT_PROCESS', 'not-started');
          }, 500)

          return resolve(response);
        })
    })
  },
  getDisposition({ commit, dispatch }, payload) {
    if (!payload.bgProcess) commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //get memo disposition that done by me
      http.get(`/memo/${payload.memoId}/${payload.type}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            if (payload.type == 'dispositions') {
              commit("SET_DISPOSITION_HISTORY", response.data);
            }
            else if (payload.type == 'mydelegation') {
              commit("SET_DELEGATION_HISTORY", response.data);
            }
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 500)
          return resolve(response);
        })
    })
  },

  getDispositionDetail({ commit, dispatch }, payload) {

    commit("SET_DISPOSITION_HISTORY_DETAIL", null);

    return new Promise((resolve) => {
      //get memo disposition that done by me
      //https://elemen4.ptpn4.co.id/service/api/memo/G1YQaGYy9zExqKVk/dispositions/qlgVAQw3rnpeGv8W
      http.get(`/memo/${payload.memoId}/disposition/${payload.dispositionId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit("SET_DISPOSITION_HISTORY_DETAIL", response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          return resolve(response);
        })
    })
  },

  setFilterParams({ commit }, payload) {
    commit('SET_FILTER_PARAMS', payload);
  },
  setSelectAllInbox({ commit }, payload) {
    commit('SET_SELECT_ALL_INBOX', payload);
  },
  setAxiosLoading({ commit }, payload) {
    commit('SET_AXIOS_LOADING', payload);
  },
  setAxiosModal({ commit }, payload) {
    commit('SET_AXIOS_LOADING', payload);
  },
  setInboxAlert({ commit }, payload) {
    commit('SET_INBOX_ALERT', payload);
  },
  setInboxAlertMessage({ commit }, payload) {
    commit('SET_INBOX_ALERT_MESSAGE', payload);
  },
  emptyInbox({ commit }, payload) {
    commit('SET_EMPTY_INBOX', payload);
  },
  setDispositionDialog({ commit }, payload) {
    commit('SET_DISPOSITION_DIALOG', payload);
  },
  setPermitAnswerDialog({ commit }, payload) {
    commit('SET_PERMIT_ANSWER_DIALOG', payload);
  },
  setPermitHistoryDialog({ commit }, payload) {
    commit('SET_PERMIT_HISTORY_DIALOG', payload);
  },
  setDispositionSimpleDialog({ commit }, payload) {
    commit('SET_DISPOSITION_SIMPLE_DIALOG', payload);
  },
  setDispositionHistoryDialog({ commit }, payload) {
    commit('SET_DISPOSITION_HISTORY_DIALOG', payload);
  },
  setPrintPreviewDialog({ commit }, payload) {
    commit('SET_PRINT_PREVIEW_DIALOG', payload);
  },
  setBroadcastDialog({ commit }, payload) {
    commit('SET_BROADCAST_DIALOG', payload);
  },
  setMemoValidationDialog({ commit }, payload) {
    commit('SET_MEMO_VALIDATION_DIALOG', payload);
  },
  setPermitTokenIsValid({ commit }, payload) {
    commit('SET_PERMIT_TOKEN_IS_VALID', payload);
  },
  setPermitProcess({ commit }, payload) {
    commit('SET_PERMIT_PROCESS', payload);
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}