import { http } from "@/http/http";
import { rowPerPage } from "@/constants/config.js";
import { mdiCheck } from "@mdi/js";
import app from '@/main';

const state = {
  sentList: undefined,
  axiosLoading: false,
  singleMemo: undefined,
  selectAll: false,
  singleMemoHistory: undefined,
  revisionHistory: undefined,
  revisionTracking: undefined,
  printPreviewDialog: false,
  memoValidationDialog: false,
  filterParams: {
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    filter: "",
    from: 1,
    to: 50,
    lastPage: 1
  }
};

const getters = {
  sentList: state => state.sentList,
  axiosLoading: state => state.axiosLoading,
  singleMemo: state => state.singleMemo,
  selectAll: state => state.selectAll,
  singleMemoHistory: state => state.singleMemoHistory,
  revisionHistory: state => state.revisionHistory,
  revisionTracking: state => state.revisionTrackin,
  printPreviewDialog: state => state.printPreviewDialog,
  memoValidationDialog: state => state.memoValidationDialog,
  filterParams: state => state.filterParams
};
const mutations = {
  SET_SENT_LIST(state, payload) {
    state.sentList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.filter = "";
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams = payload;
  },
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload;
  },
  SET_EMPTY_SENT(state) {
    state.sentList = undefined;
  },
  SET_SELECT_ALL_SENT(state, payload) {
    state.selectAll = payload;
  },
  SET_SINGLE_MEMO(state, payload) {
    state.singleMemo = payload;
  },
  SET_SINGLE_MEMO_HISTORY(state, payload) {
    state.singleMemoHistory = payload;
  },
  SET_REVISION_HISTORY(state, payload) {
    state.revisionHistory = payload;
  },
  SET_REVISION_TRACKING(state, payload) {
    state.revisionTracking = payload;
  },
  SET_PRINT_PREVIEW_DIALOG(state, payload) {
    state.printPreviewDialog = payload;
  },
  SET_MEMO_VALIDATION_DIALOG(state, payload) {
    state.memoValidationDialog = payload;
  }
};
const actions = {

  loadSent({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/sent?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SENT_LIST', response.data);
            dispatch('resources/getDataResource',{source: "counting", tipe:"all"}, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  loadSentX({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/sentx?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SENT_LIST', response.data);
            dispatch('resources/getDataResource',{source: "counting", tipe:"all"}, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },

  loadSentDetail({ commit, dispatch }, payload) {
    commit("SET_SINGLE_MEMO", undefined);
    commit('SET_AXIOS_LOADING', true)

    return new Promise(resolve => {
      http.get(`/memo/${payload}`).then(response => {
        if (
          response &&
          (response.status == 200 || response.status == "success")
        ) {
          commit("SET_SINGLE_MEMO", response.data);
          dispatch('resources/getDataResource',{source: "counting", tipe:"all"}, { root: true });
        }
        setTimeout(() => {
          commit('SET_AXIOS_LOADING', false)
        }, 300);
        return resolve(response.data);
      });
    });
  },
  makeCopyAsNewDraft({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.post(`/memo/sent/${payload.memoId}/copy-new-draft`)
        .then((response) => {
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          if (response && (response.status == 200 || response.status == 'success')) {
            app.$dialog.info({
              text: app.$t("msgbox.copy-as-new-draft-is-success"),
              title: app.$t("msgbox.copy-as-new-draft"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }
          else {
            app.$dialog.error({
              text: app.$t("error.copy-as-new-draft-failed"),
              title: app.$t("msgbox.copy-as-new-draft"),
              persistent: true,
              actions: {
                false: app.$t("button.ok")
              }
            });
          }
          return resolve(response.data);
        })
    })
  },
  setSelectAll({ commit }, payload) {
    commit('SET_SELECT_ALL_SENT', payload)
  },
  emptySent({ commit }, payload) {
    commit('SET_EMPTY_SENT', payload)
  },
  setFilterParams({ commit }, payload) {
    commit("SET_FILTER_PARAMS", payload);
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}