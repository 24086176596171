import { http } from "@/http/http";
import router from '@/router.js';
import app from '@/main';
import { mdiCheck } from "@mdi/js";

const state = {
  token: null,
  currentUser: undefined, //isAuthActive ? currentUser : (localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null),
  loginError: null,
  processing: false,
  forgotMailSuccess: null,
  resetPasswordSuccess: null,
  loginAsPerson: null,
  tokenCountDown: undefined,
  pushParams: undefined,
  forgotShow: false,
  activationShow: false,
  dontRemindBrowser: false,
};

const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  currentUser: state => state.currentUser,
  processing: state => state.processing,
  loginError: state => state.loginError,
  forgotMailSuccess: state => state.forgotMailSuccess,
  resetPasswordSuccess: state => state.resetPasswordSuccess,
  loginAsPerson: state => state.loginAsPerson,
  forgotShow: state => state.forgotShow,
};

const mutations = {
  SET_TOKEN_BEARER(state, payload) {
    state.token = payload;
  },
  SET_USER_DETAIL(state, payload) {
    state.currentUser = payload;
    state.processing = false;
    state.loginError = null;
  },
  SET_LOGIN_AS_PERSON(state, payload) {
    state.loginAsPerson = payload;
  },
  SET_LOGOUT(state) {
    state.token = undefined;
    state.pushParams = undefined;
    state.currentUser = undefined;
    state.currUserIsSecretary = undefined;
    state.processing = undefined;
    state.loginError = undefined;
    state.forgotMailSuccess = undefined;
    state.resetPasswordSuccess = undefined;
    state.dontRemindBrowser = false;
  },
  SET_PROCESSING(state, payload) {
    state.processing = payload;
    state.loginError = null;
  },
  SET_ERROR(state, payload) {
    state.loginError = payload;
    state.processing = false;
  },
  setForgotMailSuccess(state) {
    state.loginError = null;
    state.currentUser = null;
    state.processing = false;
    state.forgotMailSuccess = true;
  },
  setResetPasswordSuccess(state) {
    state.loginError = null;
    state.currentUser = null;
    state.processing = false;
    state.resetPasswordSuccess = true;
  },
  CLEAR_ERROR(state) {
    state.loginError = null;
  },
  SET_COUNTDOWN_TIME(state, payload) {
    state.tokenCountDown = payload;
  },
  SAVE_PUSH_PARAMS(state, payload) {
    state.pushParams = payload;
  },
  SET_FORGOT_SHOW(state, payload) {
    state.forgotShow = payload;
  },
  SET_ACTIVATION_SHOW(state, payload) {
    state.activationShow = payload;
  },
  SET_DONT_REMIND_BROWSER(state, payload){
    state.dontRemindBrowser = payload;
  }
};

const actions = {
  login({ commit, dispatch }, payload) {
    let storageNik = localStorage.getItem("ACTIVE_NIK");
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);

    return new Promise((resolve, reject) => {
      if (storageNik) {
        app.$dialog.notify.error(app.$t("error.multi-login-forbidden"), {
          position: "top-left",
          timeout: 5000,
        });
        return reject('fail');
      }
      http.post('/auth/login', payload)
        .then((response) => {
          if (response && response.status === 403) {
            commit('SET_PROCESSING', false);
            if (response.data.message) {
              commit("SET_ERROR", response.data.message)
            } else {
              commit("SET_ERROR", response.status)
            }
            return reject(response);
          }
          else {
            commit('SET_PROCESSING', false);
            commit("SET_ERROR", response.status)
          } //gagal login

          if (response.message == 'login_as') {
            commit('SET_LOGIN_AS_PERSON', response.data);
            return resolve(response);
          }
          else if (response.status === 'success') {
            localStorage.setItem('ACTIVE_NIK', response.user_info.nik);

            commit('CLEAR_ERROR');
            dispatch('clearAllError', { show: false, title: '', message: '' }, { root: true });

            // http.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token;
            commit('SET_TOKEN_BEARER', 'Bearer ' + response.access_token);
            commit('SET_USER_DETAIL', response.user_info);
            //getPeers and recipient
            setTimeout(() => {
              // dispatch('resources/getDataResource', { source: 'memo-recipients', bgProcess: true }, { root: true });
              // dispatch('resources/getDataResource', { source: 'peer-revisers', bgProcess: true }, { root: true });
              // dispatch('resources/getDataResource', { source: 'atasan-krani', bgProcess: true }, { root: true });

            }, 500);
            return resolve(response);
            // dispatch('resources/getDataResource', {source: 'disposition-recipients', bgProcess: false}, { root: true });
            // dispatch('resources/getDataResource', {source: 'disposition-messages', bgProcess: false}, { root: true });
            // dispatch('resources/getDataResource', {source: 'izin-prinsip-messages', bgProcess: false}, { root: true });
            // dispatch('resources/getDataResource', {source: 'broadcast-recipients', bgProcess: false}, { root: true });
          }
        })

    })
  },

  subscribeNotif({ commit }, payload) {
    commit('SET_PROCESSING', true);
    return new Promise((resolve, reject) => {
      http.post("/memo/broadcast/subscribe", payload).then((response) => {
        commit('SET_PROCESSING', false);
        commit('SAVE_PUSH_PARAMS', payload);
        if (response !== true) {
          return reject(response);
        }
        //save the params to vuex
        return resolve(response);
      });

    });
  },
  unsubscribeNotif({ state }) {
    let params = state.pushParams;
    if (!params) return true;
    return new Promise((resolve, reject) => {
      http.post("/memo/broadcast/subscribe/delete", params)
        .then((response) => {
          if (response.data !== true) {
            return reject(response);
          }
          return resolve(response);
        });
    });

  },
  logout({ commit, dispatch }, payload) {
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);

    return new Promise((resolve) => {
      http.get('/auth/logout', payload)
        .then((response) => {
          if (response && (response.status == 200 || response == 'success_logout')) {
            dispatch("signOut");
          }
          return resolve(response);
        })
    })
  },
  getSmsToken({ commit }, payload) {
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);
    return new Promise((resolve) => {
      http.post(`/auth/otp-request`, payload)
        .then((response) => {
          if (response) {
            commit('SET_PROCESSING', false);
            if (response == 'success') {
              commit("SET_COUNTDOWN_TIME", 60);
            }
            else {
              commit("SET_COUNTDOWN_TIME", undefined);
            }
          }
          return resolve(response);
        })
    })
  },
  forgotPassword({ commit }) {
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);
  },
  resetPassword({ commit }, payload) {
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);
    return new Promise((resolve, reject) => {
      /**
       * 'username' => 'required|max:15',
       'password' => 'required|string|max:50',
       'otp' => 'required|string|max:4',
       */
      http.post(`/auth/reset`, payload)
        .then((response) => {
          if (response) {
            commit('SET_PROCESSING', false);
            commit("SET_COUNTDOWN_TIME", undefined);

            if (response.data == 'your-password-has-been-reset-successfully') {

              app.$dialog.info({
                text: app.$t("msgbox.your-password-has-been-reset-successfully"),
                title: app.$t("msgbox.reset-password"),
                persistent: true,
                actions: [{ text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }]
              });

              commit('SET_FORGOT_SHOW', false);

            }
            else if (response.data == 'account_is_not_active') {

              app.$dialog.warning({
                text: app.$t("msgbox.account_is_not_active"),
                title: app.$t("msgbox.reset-password"),
                persistent: true,
                actions: [{ text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }]
              });

            }
            else if (response.data == 'otp-token-invalid--or-token-expired') {
              app.$dialog.warning({
                text: app.$t("msgbox.otp-token-invalid--or-token-expired"),
                title: app.$t("msgbox.reset-password"),
                persistent: true,
                actions: [{ text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }]
              });
            }
            return resolve(response);
          }
          else {
            commit('SET_PROCESSING', false);
            return reject('fail');
          }
        })
    })
  },
  activateAccount({ commit }, payload) {
    commit('CLEAR_ERROR');
    commit('SET_PROCESSING', true);
    return new Promise((resolve, reject) => {
      /**
       * 'username' => 'required|max:15',
       'password' => 'required|string|max:50',
       'otp' => 'required|string|max:4',
       */
      http.post(`/auth/activate`, payload)
        .then((response) => {
          if (response) {
            commit('SET_PROCESSING', false);
            commit("SET_COUNTDOWN_TIME", undefined);

            if (response.data == 'your-account-has-been-activated-successfully') {

              app.$dialog.info({
                text: app.$t("msgbox.your-account-has-been-activated-successfully"),
                title: app.$t("msgbox.account-activation"),
                persistent: true,
                actions: [{ text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }]
              });

              commit('SET_ACTIVATION_SHOW', false);

            }
            else {

              app.$dialog.warning({
                text: app.$t("msgbox.account-cannot-activate"),
                title: app.$t("msgbox.account-activation"),
                persistent: true,
                actions: [{ text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }]
              });

            }
            return resolve(response);
          }
          else {
            commit('SET_PROCESSING', false);
            return reject('fail');
          }
        })
    })
  },
  signOut({ commit }) {
    return new Promise((resolve) => {
      localStorage.removeItem('ACTIVE_NIK');
      localStorage.removeItem('ELEMEN4');
      resolve()
    }).then(() => {
      commit('SET_LOGOUT')
      router.push("/auth/welcome").catch(e => {
        console.error(e);
        console.clear();
      });
    }, _error => {
      console.error(_error);
    })
  },
  setProcessing({ commit }, payload) {
    commit('SET_PROCESSING', payload)
  },
  setError({ commit }, payload) {
    commit('SET_ERROR', payload)
  },
  loginAsCancel({ commit }) {
    commit('SET_LOGIN_AS_PERSON', null);
    commit('SET_ERROR', null);
    commit('SET_PROCESSING', false);
  },
  setCountDownTime({ commit }, payload) {
    commit("SET_COUNTDOWN_TIME", payload);
  },
  setforgotShow({ commit }, payload) {
    commit("SET_FORGOT_SHOW", payload);
  },
  setActivationShow({ commit }, payload) {
    commit("SET_ACTIVATION_SHOW", payload);
  },
  setDontRemindBrowser({commit}, payload) {
    commit('SET_DONT_REMIND_BROWSER', payload)
  },
  setLoginAsPerson({commit, payload}){
    commit("SET_LOGIN_AS_PERSON", payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}