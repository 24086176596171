import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';

import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';
import VueI18n from 'vue-i18n';
import VuetifyDialog from 'vuetify-dialog';
import VueParticles from 'vue-particles';
import VueClipboard from 'vue-clipboard2';
import VueDragscroll from 'vue-dragscroll';
import PortalVue from 'portal-vue';
import 'vuetify-dialog/dist/vuetify-dialog.css';

import './plugins/dayjs.js';
import './plugins/base';

require('froala-editor/js/froala_editor.pkgd.min.js');
import 'froala-editor/js/plugins.pkgd.min.js';
import VueFroala from 'vue-froala-wysiwyg';

// Require Froala Editor css files.
require('froala-editor/css/froala_editor.pkgd.min.css');
require('froala-editor/css/froala_style.min.css');
require('froala-editor/js/languages/id');

import commonFunctions from "./utils/CommonFunction";

//the lang source
import en from './locales/en.json';
import id from './locales/id.json';

import { defaultLocale, localeOptions } from './constants/config';

Vue.use(VueParticles);
Vue.use(VueFroala);
Vue.use(VueI18n);
Vue.use(PortalVue);

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
});
Vue.use(VueClipboard);

Vue.use(VueDragscroll);
Vue.config.productionTip = false;

const messages = { id: id, en: en };
const locale = (localStorage.getItem('currentLanguage') &&
localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ?
localStorage.getItem('currentLanguage') : defaultLocale;

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'id',
  messages
});


Vue.mixin(commonFunctions);

export default new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app');
