import axios from 'axios';
import Vue from 'vue';
import { apiUrl } from "@/constants/config";
import store from "../store";
import VueCookies from 'vue-cookies';
import qs from "qs";

Vue.prototype.$http = axios;
Vue.use(VueCookies);

function parseBody(response) {
	//  if (response.status === 200 && response.data.status.code === 200) { // - if use custom status code
	if (response.status === 200) {
		return response.data
	} else {
		return 'error.999'
	}
}

// axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-CSRF-TOKEN'] = VueCookies.get("XSRF-TOKEN");

let instance = axios.create({
	withCredentials: true,
	baseURL: apiUrl,
	headers: {
		// 'Access-Control-Allow-Origin': 'http://localhost:8080',
		// 'Access-Control-Allow-Credentials' : 'true',
		// 'Content-Type': 'Application/Json'
	},
	paramsSerializer: function (params) {
		return qs.stringify(params, { indices: false })
	}
})

// instance.interceptors.request.use(req => {
// 	console.log(req.url);
// 	return req;
// });

instance.interceptors.request.use((config) => {

	const token = store.state.user.token;
	if (token) {
		config.headers.authorization = token;
	}
	config.headers.accept = 'application/json';
	config.crossorigin = true;
	return config

}, error => Promise.reject(error));

instance.interceptors.response.use((response) => {
	store.dispatch("setNetworkError", false); //reset all error
	return parseBody(response); //return response to components
}, error => {
	let authPath = window.location.pathname;
	if (!error.response) {
		// return Promise.reject(error)
		store.dispatch("setNetworkError", true);
		return 'error.undefined';
	}
	else {
		if (error.response && error.response.status == 401) {
			if (authPath.includes("/auth/welcome")) {
				//login error
				// store.dispatch("setBackendError",
				// 	{
				// 		status: error.response.status,
				// 		message: error.response.data ? error.response.data : undefined
				// 	});
				return error.response;
			}
			else {
				return store.dispatch("user/signOut", true);
			}
		}
		else if ([500, 400, 405, 404, 403, 419, 422, 429].includes(error.response.status)) {
			store.dispatch("setBackendError",
				{
					status: error.response.status,
					message: error.response.data ? error.response.data : undefined
				});
			return error.response;
		}
	}
})

export const http = instance
