import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthGuard from "./utils/AuthGuard";

import NotFoundComponent from "@/views/errors/Error404.vue";
import LinkResolver from "@/views/pages/LinkResolver.vue";
import GlobalLinkResolver from "@/views/pages/GlobalLinkResolver.vue";

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}


// let randomText = (length) => {
//    var result           = '';
//    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//    var charactersLength = characters.length;
//    for ( var i = 0; i < length; i++ ) {
//       result += characters.charAt(Math.floor(Math.random() * charactersLength));
//    }
//    return result;
// };

/*
Basic Plan
A. Create Memo Biasa (Modal)
B. Create Memo Dir  Izin Prinsip (Modal)
1. Create Memo Biasa (static Page)
2. Create Memo Dir Izin Prinsip (static Page)

3. Memo/Draft = List Memo yang kubuat
3.1. Memo/{}/draft-detail = Modal Editor (Edit Mode)

4 Memo/Revision
4.1 Memo/{}/revision-detail = Detail revision task (my job)

5. Memo/Sent
5.1 Memo/{}/sent-detail = Memo Sent Detail

6. Memo/Inbox = list
6.1 Memo/{}/memo-detail = Memo Inbox Detail
6.2 Memo/{}/permit-detail = Memo Inbox Izin Prinsip Detail

7 Task/Disposition = List Disposition (my job)
7.1 Task/{}/disposition-detail = Detail task disposition (my job)

*/
const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: () => import('@/layouts/Layout'),
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/views/dashboard'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.my-dashboard' }
      },
      {
        name: 'draft',
        path: 'memo/draft',
        component: () => import('@/views/pages/memo/draft'),
        meta: { loginRequired: true, adminRequired: false, roles: ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS", "KRANI","SEKRETARIS_KRANI"], title: 'pagetitle.memo-draft' }
      },
      {
        name: 'terminated',
        path: 'memo/terminated',
        component: () => import('@/views/pages/memo/terminated'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-terminated' }
      },
      {
        name: 'terminated.detail',
        path: 'memo/terminated/:id',
        component: () => import('@/views/pages/memo/terminated-revoked-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.terminated-detail' }
      },
      {
        name: 'revision',
        path: 'memo/revision',
        component: () => import('@/views/pages/memo/revision'),
        meta: { loginRequired: true, adminRequired: false, roles: ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS"], title: 'pagetitle.memo-revision' }
      },
      {
        name: 'revision.detail',
        path: 'memo/revision/:id',
        component: () => import('@/views/pages/memo/revision-detail'),
        meta: { loginRequired: true, adminRequired: false, roles: ["KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS","PJP", "PJP_KANPUS","DIREKTUR"], title: 'pagetitle.draft-revision-detail' }
      },
      {
        name: 'approval',
        path: 'memo/draft-approval',
        component: () => import('@/views/pages/memo/revision'),
        meta: { loginRequired: true, adminRequired: false, roles: ["PJP", "PJP_KANPUS", "DIREKTUR"], title: 'pagetitle.draft-approval' }
      },
      {
        name: 'approval-x',
        path: 'memo/draft-x-approval',
        component: () => import('@/views/pages/memo/revision'),
        meta: { loginRequired: true, adminRequired: false, roles: ["PJP", "PJP_KANPUS", "DIREKTUR"], title: 'pagetitle.draft-x-approval' }
      },
      {
        name: 'draft.detail',
        path: 'memo/draft/:id',
        component: () => import('@/views/pages/memo/draft-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.draft-detail' }
      },
      {
        name: 'draft.edit',
        path: 'memo/draft/:id/:edit',
        component: () => import('@/views/pages/memo/draft-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.draft-detail' }
      },
      {
        name: 'inbox',
        path: 'memo/inbox',
        component: () => import('@/views/pages/memo/inbox'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-inbox' }
      },
      {
        name: 'incoming',
        path: 'memo/incoming',
        component: () => import('@/views/pages/memo/inboxincoming'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-incoming' }
      },
      {
        name: 'permit',
        path: 'memo/permit',
        component: () => import('@/views/pages/memo/permit'),
        meta: { loginRequired: true, adminRequired: false, roles: ["DIREKTUR","SEKRETARIS_DIRSEVP"], title: 'pagetitle.memo-permit' }
      },
      {
        name: 'sent',
        path: 'memo/sent',
        component: () => import('@/views/pages/memo/sent'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-sent' }
      },
      {
        name: 'sentx',
        path: 'memo/sentx',
        component: () => import('@/views/pages/memo/sent'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-sentx' }
      },
      // {
      //   name: 'sentx.detail',
      //   path: '/memo/sentx/:id',
      //   component: () => import('@/views/pages/memo/inbox-detail'),
      //   meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.sentx-detail' }
      // },
      {
        name: 'ccsentx',
        path: 'memo/cc-sentx',
        component: () => import('@/views/pages/memo/cc-sentx'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-cc-sentx' }
      },
      {
        name: 'ccsentx.detail',
        path: 'memo/cc-sentx/:id',
        component: () => import('@/views/pages/memo/cc-sentx-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-cc-sentx-detail' }
      },
      {
        name: 'sent.detailmac',
        path: 'memo/sent/:id/mac',
        component: () => import('@/views/pages/memo/draft-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.draft-detail' }
      },
      {
        name: 'sent.detailmar',
        path: 'memo/sent/:id/mar',
        component: () => import('@/views/pages/memo/revision-detail'),
        meta: { loginRequired: true, adminRequired: false, roles: ["PJP", "PJP_KANPUS", "DIREKTUR", "KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS","SEKRETARIS","SEKRETARIS_DIRSEVP","SEKRETARIS_KRANI"], title: 'pagetitle.draft-revision-detail' }
      },
      {
        name: 'sentx.detailmac',
        path: 'memo/sentx/:id/mac',
        component: () => import('@/views/pages/memo/draft-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.draft-detail' }
      },
      {
        name: 'sentx.detailmar',
        path: 'memo/sentx/:id/mar',
        component: () => import('@/views/pages/memo/revision-detail'),
        meta: { loginRequired: true, adminRequired: false, roles: ["PJP", "PJP_KANPUS", "DIREKTUR", "KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS","SEKRETARIS","SEKRETARIS_DIRSEVP","SEKRETARIS_KRANI"], title: 'pagetitle.draft-revision-detail' }
      },
      {
        name: 'revoked',
        path: 'memo/revoked',
        component: () => import('@/views/pages/memo/revoked'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-revoked' }
      },
      {
        name: 'revoked.detail',
        path: 'memo/revoked/:id',
        component: () => import('@/views/pages/memo/terminated-revoked-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.revoked-detail' }
      },
      {
        name: 'inbox.detail',
        path: '/memo/inbox/:id',
        component: () => import('@/views/pages/memo/inbox-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-inbox-detail' }
      },
      {
        name: 'incoming.detail',
        path: '/memo/incoming/:id',
        component: () => import('@/views/pages/memo/inbox-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-incoming-detail' }
      },
      {
        name: 'inbox.permit-detail',
        path: '/memo/permit/:id',
        component: () => import('@/views/pages/memo/permit-detail'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.memo-inbox-permit-detail' }
      },
      {
        name: 'disposition',
        path: 'task/disposition',
        component: () => import('@/views/pages/task/disposition'),
        meta: { loginRequired: true, adminRequired: false, roles: ["DIREKTUR","PJP", "PJP_KANPUS", "KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS", "KRANI","SEKRETARIS","SEKRETARIS_KRANI","SEKRETARIS_DIRSEVP"], title: 'pagetitle.disposition-inbox' }
      },
      {
        name: 'disposition-detail',
        path: 'task/disposition/:id',
        component: () => import('@/views/pages/task/disposition-detail'),
        meta: { loginRequired: true, adminRequired: false, roles: ["DIREKTUR","PJP", "PJP_KANPUS", "KARPIM", "KARPIM_KANPUS", "KASUBAG", "KADIS", "KRANI","SEKRETARIS","SEKRETARIS_KRANI","SEKRETARIS_DIRSEVP"], title: 'pagetitle.disposition-detail' }
      },
      {
        name: 'setting',
        path: '/setting',
        component: () => import('@/views/pages/setting'),
        meta: { loginRequired: true, title: 'pagetitle.setting' }
      },
      {
        name: 'setting.institutions',
        path: '/setting/institutions',
        component: () => import('@/views/pages/setting/institutions'),
        meta: { loginRequired: true, institutionAdminRequired: true, title: 'pagetitle.setting-institutions' }
      },
      {
        name: 'setting.official',
        path: '/setting/officials',
        component: () => import('@/views/pages/setting/officials'),
        meta: { loginRequired: true, adminRequired: true, title: 'pagetitle.setting-officials' }
      },
      {
        name: 'setting.subordinate',
        path: '/setting/subordinate',
        component: () => import('@/views/pages/setting/subordinate'),
        meta: { loginRequired: true, adminRequired: true, title: 'pagetitle.subordinate-mapping' }
      },
      {
        name: 'setting.application',
        path: '/setting/application',
        component: () => import('@/views/pages/setting/application'),
        meta: { loginRequired: true, adminRequired: true, superAdminRequired: true, title: 'pagetitle.application-setting' }
      },
      {
        name: 'report',
        path: '/report',
        component: () => import('@/views/pages/reports/index.vue'),
        meta: { loginRequired: true, adminRequired: false, title: 'pagetitle.report-elemen4' }
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/welcome',
    component: () => import('@/layouts/Auth'),
    children: [
      {
        name: 'auth.welcome',
        path: '/auth/welcome',
        component: () => import('@/views/auth/'),
        meta: { mustNotLogin: true, title: 'pagetitle.welcome' }
      },
      {
        name: 'auth.welcome-action',
        path: '/auth/welcome/:action',
        component: () => import('@/views/auth/'),
        meta: { mustNotLogin: true, title: 'pagetitle.welcome' }
      },

    ]
  },
   { 
    name: 'tlink.resolver',
    path: '/t-link/:mode/:id',
    component: GlobalLinkResolver,
    meta: { loginRequired: true, title: 'pagetitle.global-link-resolver' }
   },  
   { 
    name: 'link.resolver',
    path: '/link/:id',
    component: LinkResolver,
    meta: { loginRequired: true, title: 'pagetitle.link-resolver' }
   },

  { path: '*', component: NotFoundComponent }
];

const router = new VueRouter({
  linkExactActiveClass: "active", // active class for *exact* links.
  linkActiveClass: "active",
  routes,
  mode: "history",
  base: process.env.BASE_URL
});

router.beforeEach(AuthGuard);

export default router;