import { http } from "@/http/http";
import app from '@/main';
import router from '@/router.js';
import { mdiCheck } from "@mdi/js";
import { rowPerPage } from "@/constants/config.js";

const state = {
  //==============================================
  //variable for draft create/edit================
  //==============================================
  draftIsSaved: false,
  draftList: undefined,
  singleDraft: undefined,
  selectAllDraft: false,
  singleDraftHistory: undefined,
  singleDraftLog: undefined,
  singleDraftInfo: undefined,
  selectUplineDialog: false,
  draftMode: "create",
  memoToReply: undefined,
  editGeneralMemo: false,
  editPermitMemo: false,
  editParams: null,
  //==============================================
  //variable for revision ========================
  //==============================================
  revisionList: undefined,
  singleRevision: undefined,
  singleRevisionInfo: undefined,
  selectAllRevision: false,
  selectPeersDialog: false,
  // singleRevisionHistory: undefined,
  // singleRevisionTracking: undefined,
  //==============================================
  //variable for multipurpose (draft and revision)
  //==============================================
  axiosLoading: false,
  memoSigning: false,
  //==============================================
  filterParams: {
    only: "",
    sortBy: "datetime",
    sortByDesc: "desc",
    perPage: rowPerPage,
    currentPage: 1,
    filter: "",
    from: 1,
    to: 50,
    lastPage: 1
  }
};
const getters = {
  draftList: state => state.draftList,
  axiosLoading: state => state.axiosLoading,
  memoSigning: state => state.memoSigning,
  singleDraft: state => state.singleDraft,
  selectAllDraft: state => state.selectAllDraft,
  singleDraftHistory: state => state.singleDraftHistory,
  singleDraftLog: state => state.singleDraftLog,
  singleDraftInfo: state => state.singleDraftInfo,
  selectPeersDialog: state => state.selectPeersDialog,
  selectUplineDialog: state => state.selectUplineDialog,
  filterParams: state => state.filterParams
};
const mutations = {
  SET_DRAFT_LIST(state, payload) {
    state.draftList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_REVISION_LIST(state, payload) {
    state.revisionList = payload;
    state.filterParams.perPage = payload.per_page;
    state.filterParams.currentPage = payload.current_page;
    state.filterParams.lastPage = payload.last_page;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  },
  SET_AXIOS_LOADING(state, payload) {
    state.axiosLoading = payload
  },
  SET_MEMO_SIGNING(state, payload) {
    state.memoSigning = payload
  },
  SET_EMPTY_DRAFT(state) {
    state.draftList = undefined;
  },
  SET_MEMO_TO_REPLY(state, payload) {
    state.memoToReply = payload;
  },
  SET_EMPTY_REVISION(state) {
    state.revisionList = undefined;
  },
  SET_SELECT_ALL_DRAFT(state, payload) {
    if (typeof payload !== "boolean") return;
    state.selectAllDraft = payload;
  },
  SET_SINGLE_DRAFT(state, payload) {
    state.singleDraft = payload
  },
  SET_SINGLE_REVISION(state, payload) {
    state.singleRevision = payload
  },
  SET_SINGLE_REVOKED_TERMINATED(state, payload) {
    state.singleRevokedTerminated = payload;
  },
  SET_DRAFT_IS_SAVED(state,payload) {
    state.draftIsSaved = payload;
  },
  SET_SINGLE_REVISION_INFO(state, payload) {
    state.singleRevisionInfo = payload
  },
  SET_SINGLE_DRAFT_HISTORY(state, payload) {
    state.singleDraftHistory = payload
  },
  SET_SINGLE_DRAFT_LOG(state, payload) {
    state.singleDraftLog = payload
  },
  SET_SINGLE_DRAFT_INFO(state, payload) {
    state.singleDraftInfo = payload
  },
  SET_EDIT_GENERAL_MEMO(state, payload) {
    state.editGeneralMemo = payload.show;
    state.editParams = payload.editParams;
  },
  SET_EDIT_PERMIT_MEMO(state, payload) {
    state.editPermitMemo = payload.show;
    state.editParams = payload.editParams;
  },
  SET_SELECT_PEERS_DIALOG(state, payload) {
    state.selectPeersDialog = payload
  },
  SET_SELECT_UPLINE_DIALOG(state, payload) {
    state.selectUplineDialog = payload
  },
  SET_SEARCH_QUERY(state, payload) {
    state.filterParams.filter = payload;
  },
  SET_FILTER_PARAMS(state, payload) {
    state.filterParams.sortBy = payload.sortBy;
    state.filterParams.only = payload.only;
    state.filterParams.sortByDesc = payload.sortByDesc;
    state.filterParams.perPage = payload.perPage;
    state.filterParams.currentPage = payload.currentPage;
    state.filterParams.lastPage = payload.lastPage;
    state.filterParams.filter = payload.filter;
    state.filterParams.from = payload.from;
    state.filterParams.to = payload.to;
    state.filterParams.total = payload.total;
  }
};
const actions = {
  loadDraft({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true)

    return new Promise((resolve) => {
      http.get(`/memo/draft?page=${payload.currentPage}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DRAFT_LIST', response.data)
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        })
    })
  },
  loadRevisions({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      http.get(`/memo/myrevision?page=${payload.currentPage}${payload.only}&filter=${payload.filter}&sortby=${payload.sortBy}&sortbydesc=${payload.sortByDesc}&per_page=${payload.perPage}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_REVISION_LIST', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all"}, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 300);

          return resolve(response.data)
        });
    });
  },
  loadDraftDetail({ commit, dispatch }, payload) {
    //fungsi mengambil data ke backend untuk dimunculkan pada form edit
    //atau pada detail revisi
    //  api/memo/draft/{id}
    commit('SET_SINGLE_DRAFT', undefined);
    commit('SET_AXIOS_LOADING', true);
      if (!payload) return;
    return new Promise((resolve) => {
      http.get(`/memo/draft/${payload.memoId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_DRAFT', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data);
        })
    })
  },
  loadDraftXDetail({ commit, dispatch }, payload) {
    //fungsi mengambil data ke backend untuk dimunculkan pada form edit
    //atau pada detail revisi
    //  api/memo/draft/{id}
    commit('SET_SINGLE_DRAFT', undefined);
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      http.get(`/memo/draftx/${payload.memoId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_DRAFT', response.data);
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data);
        })
    })
  },
  loadRevisionsDetail({ commit, dispatch }, payload) {
    //fungsi mengambil data ke backend untuk dimunculkan pada form edit
    //atau pada detail revisi
    //  api/memo/draft/{id}
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.get(`/memo/myrevision/${payload.memoId}?mode=${payload.mode}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
            commit('SET_SINGLE_REVISION', response.data);
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data);
        })
    })
  },
  loadTerminatedDetail({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //cek creator nik yg aktif = payload.creator_nik
      //memo/draft/{memo_id}
      http.get(`/memo/terminated/${payload.memoId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('resources/getDataResource', { source: "counting", tipe:"all" }, { root: true });
            commit('SET_SINGLE_REVOKED_TERMINATED', response.data);
          }
          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 300);

          return resolve(response.data);
        })
    })
  },

  sendMemo({ commit }, payload) {
    commit('SET_MEMO_SIGNING', true);
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/${payload.memoId}/send`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.send-memo-is-success"),
              title: app.$t("msgbox.send-memo"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            }).then(router.push("/memo/draft-approval"));
          }

          // dispatch('loadRevisions', state.filterParams);

          setTimeout(() => {
            commit('SET_MEMO_SIGNING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  sendLetterX({ commit }, payload) {
    commit('SET_MEMO_SIGNING', true);
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/${payload.memoId}/sendx`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.final x letter is successfully issued"),
              title: app.$t("msgbox.x letter issued"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            }).then(router.push("/memo/draft-approval"));
          }

          // dispatch('loadRevisions', state.filterParams);

          setTimeout(() => {
            commit('SET_MEMO_SIGNING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  
  submitX({commit}, payload) {
    commit('SET_MEMO_SIGNING', true);
    //this function is for PJP level only
    //to submit to BOD/Dir level of XLetter
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/myrevision/${payload.memoId}/submitx`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.submit-xletter-is-success"),
              title: app.$t("msgbox.submit-xletter"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            }).then(router.push("/memo/draft-approval"));
          }

          // dispatch('loadRevisions', state.filterParams);

          setTimeout(() => {
            commit('SET_MEMO_SIGNING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  revokeMemo({ commit /*, rootState, dispatch*/ }, payload) {
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      //submit is send to superiorpi/memo/sent/{id}/revoke
      http.post(`/memo/sent/${payload.memoId}/revoke`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            app.$dialog.info({
              text: app.$t("msgbox.revoke-memo-is-success"),
              title: app.$t("msgbox.revoke-memo"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
            // dispatch('sent/loadSent', { filterParams: rootState.sent.filterParams }, { root: true });
          }

          // dispatch('loadRevisions', state.filterParams);

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },

  submitDraftFromList({ commit, dispatch, state }, payload) {
    //submit draft from selected item on the draft list.
    commit('SET_AXIOS_LOADING', true);
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/submitlist`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.submit-draft-to-upline-success"),
              title: app.$t("msgbox.submit-success"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }

          dispatch('loadRevisions', state.filterParams);

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  // submitRevisionFromList({ commit, dispatch, state }, payload) {
  //   commit('SET_AXIOS_LOADING', true);
  //   return new Promise((resolve) => {
  //     //submit revision is send to superior
  //     http.post(`/memo/myrevision/${payload.formData.id_hash}/submit`, payload.formData)
  //       .then((response) => {
  //         if (response && (response.status == 200 || response.status == 'success')) {

  //           app.$dialog.info({
  //             text: app.$t("msgbox.submit-revision-to-upline-success"),
  //             title: app.$t("msgbox.submit-success"),
  //             persistent: true
  //           });
  //         }

  //         dispatch('loadRevisions', state.filterParams);

  //         setTimeout(() => {
  //           commit('SET_AXIOS_LOADING', false)
  //         }, 500)

  //         return resolve(response);
  //       })
  //   })
  // },
  submitMyDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    commit('SET_DRAFT_IS_SAVED', false);
    //save,submit,share_to_revision
    return new Promise((resolve) => {
      const apiUrl = payload.isSuratX === true ? 'suratx' : 'memo';
      //submit is send to superior
      http.post(`/${apiUrl}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DRAFT_IS_SAVED', true);
            dispatch('loadDraft', state.filterParams);

            switch (payload.action) {
              case 'save':
                //save as draft
                app.$dialog.info({
                  text: app.$t("msgbox.save-as-draft-success"),
                  title: app.$t("msgbox.save-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              case 'submit':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-upline-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;
              case 'direct_send':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.send-memo-is-success"),
                  title: app.$t("msgbox.send-memo"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;
              case 'share_to_revision':
                //submit to peers
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-peers-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              default:
                break;
            }
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  submitMyPermitDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //save,submit,share_to_revision
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo-izin-prinsip`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            dispatch('loadDraft', state.filterParams);

            switch (payload.action) {
              case 'save':
                //save as draft
                app.$dialog.info({
                  text: app.$t("msgbox.save-as-draft-success"),
                  title: app.$t("msgbox.save-permit"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              case 'submit':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-upline-success"),
                  title: app.$t("msgbox.submit-permit"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;
              case 'share_to_revision':
                //submit to peers
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-peers-success"),
                  title: app.$t("msgbox.submit-permit"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              default:
                break;
            }
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  updateMyDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    commit('SET_DRAFT_IS_SAVED', false);
    
    return new Promise((resolve) => {
      //update just save for me
      http.post(`/memo/${payload.memoId}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_DRAFT_IS_SAVED', true);
            switch (payload.action) {
              case 'save':
                //save as draft
                app.$dialog.info({
                  text: app.$t("msgbox.save-as-draft-success"),
                  title: app.$t("msgbox.save-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              case 'submit':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-upline-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;
              case 'share_to_revision':
                //submit to peers
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-peers-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              default:
                break;
            }
            dispatch('loadDraft', state.filterParams);
            commit('SET_EDIT_GENERAL_MEMO', { show: false, editParams: null });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  updateMyPermitDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //update just save for me
      http.post(`/memo-izin-prinsip/${payload.memoId}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            dispatch('loadDraft', state.filterParams);

            commit('SET_EDIT_PERMIT_MEMO', { show: false, editParams: null });

            switch (payload.action) {
              case 'save':
                //save as draft
                app.$dialog.info({
                  text: app.$t("msgbox.save-as-draft-success"),
                  title: app.$t("msgbox.save-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });
                break;
              case 'submit':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-upline-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });
                break;
              case 'share_to_revision':
                //submit to peers
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-peers-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });
                break;
              default:
                break;
            }

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  updateMyXDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);

    return new Promise((resolve) => {
      //update just save for me
      http.post(`/suratx/${payload.memoId}`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            switch (payload.action) {
              case 'save':
                //save as draft
                app.$dialog.info({
                  text: app.$t("msgbox.save-as-draft-success"),
                  title: app.$t("msgbox.save-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              case 'submit':
                //submit to upline
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-upline-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;
              case 'share_to_revision':
                //submit to peers
                app.$dialog.info({
                  text: app.$t("msgbox.submit-draft-to-peers-success"),
                  title: app.$t("msgbox.submit-success"),
                  persistent: true,
                  actions: [
                    { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
                  ]
                });

                break;

              default:
                break;
            }
            dispatch('loadDraft', state.filterParams);
            commit('SET_EDIT_GENERAL_MEMO', { show: false, editParams: null });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        })
    })
  },
  shareMyRevision({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //sharing revision
    return new Promise((resolve) => {
      //give the content_is_changed param to make sure the content changed is recorded in DB
      //reviser_nik(comma delimiter), hal,isi
      http.post(`/memo/myrevision/${payload.memoId}/share`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('loadRevisions', state.filterParams);

            app.$dialog.info({
              text: app.$t("msgbox.peer-revision-request-is-success"),
              title: app.$t("msgbox.share-revision"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  submitMyRevision({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //send my revision to upline as original reviser
    // api/memo/myrevision/{id}/submit
    return new Promise((resolve) => {

      http.post(`/memo/myrevision/${payload.memoId}/submit`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.submit-revision-is-success"),
              title: app.$t("msgbox.submit-revision"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  submitRevisionAsPeer({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //send my revision as Peer Reviser
    // api/memo/{id}/revisions/{revisionId}/revise
    return new Promise((resolve) => {

      http.post(`/memo/${payload.memoId}/revisions/${payload.revisionId}/revise`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.submit-revision-as-peer-is-success"),
              title: app.$t("msgbox.submit-revision"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  submitRevisionAsPeerList({ commit, dispatch }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //send my revision as Peer Reviser
    // api/memo/{id}/revisions/{revisionId}/revise
    return new Promise((resolve) => {

      http.post(`/memo/reviselist`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            dispatch('loadRevisions', state.filterParams);
            app.$dialog.info({
              text: app.$t("msgbox.submit-revision-as-peer-is-success"),
              title: app.$t("msgbox.submit-revision"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  saveOnlyRevision({ commit }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //send my revision as Peer Reviser
    // api/memo/{id}/revisions/{revisionId}/revise
    return new Promise((resolve) => {

      http.post(`/memo/${payload.memoId}/revisions/${payload.revisionId}/save-only`, payload.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.save-only-revision-as-peer-is-success"),
              title: app.$t("msgbox.save-only-revision"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });
          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });
  },
  deleteMyDraft({ commit, dispatch, state }, payload) {
    commit('SET_AXIOS_LOADING', true);
    //save,submit,share_to_revision
    return new Promise((resolve) => {
      //submit is send to superior
      http.post(`/memo/draft`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            dispatch('loadDraft', state.filterParams);

            app.$dialog.info({
              text: app.$t("msgbox.delete-draft-success"),
              title: app.$t("msgbox.delete-draft"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false);
          }, 500)

          return resolve(response);
        })
    })
  },
  terminateThisDraft({ commit, dispatch, state }, payload) {
    //do action to change memo status to terminate (no undo)
    // api/memo/myrevision/{id}/terminate
    commit('SET_AXIOS_LOADING', true);
    //sharing revision
    return new Promise((resolve) => {
      //give the content_is_changed param to make sure the content changed is recorded in DB
      //reviser_nik(comma delimiter), hal,isi
      http.post(`/memo/${payload.revisionId}/terminate`, payload.formData)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            dispatch('loadRevisions', state.filterParams);
            app.$dialog.info({
              text: app.$t("msgbox.terminate-draft-is-success"),
              title: app.$t("msgbox.terminate-draft"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

          }

          setTimeout(() => {
            commit('SET_AXIOS_LOADING', false)
          }, 500)

          return resolve(response);
        });
    });

  },
  deleteServerAttachment({ commit, dispatch, state }, payload) {
    // http://localhost:8000/api/memo/memo_hash_id/attachments/attachment_hash_id
    commit('SET_AXIOS_LOADING', false);

    return new Promise((resolve) => {

      http
        //api/memo/{id}/attachments/{attachmentId}
        .delete(`/memo/${payload.memoId}/attachments/${payload.id_hash}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {

            app.$dialog.info({
              text: app.$t("msgbox.delete-attachment-success"),
              title: app.$t("msgbox.delete-attachment"),
              persistent: true,
              actions: [
                { text: app.$t("button.ok"), color: "success", key: false, icon: mdiCheck }
              ]
            });

            // dispatch('loadDraft', state.filterParams);
            dispatch('loadDraftDetail', state.editParams);

          }
          return resolve(response);
        });
    })
  },
  getDraftHistory({ commit }, payload) {
    //no axios loading here
    commit('SET_SINGLE_DRAFT_HISTORY', undefined);
    return new Promise((resolve) => {
      http.get(`/memo/${payload.rowId}/history/?table=${payload.tableName}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_DRAFT_HISTORY', response.data);
          }
          return resolve(response.data);
        })
    })
  },
  getDraftLog({ commit }, payload) {
    //no axios loading here
    return new Promise((resolve) => {
      http.get(`/memo/${payload.memoId}/history/?log=1&table=revision`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_DRAFT_LOG', response.data);
          }
          return resolve(response.data);
        })
    })
  },
  getDraftInfo({ commit }, payload) {
    //no axios loading here
    commit('SET_SINGLE_DRAFT_INFO', undefined);
    return new Promise((resolve) => {
      http.get(`/memo/${payload.memoId}/info`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_DRAFT_INFO', response.data);
          }
          return resolve(response.data);
        })
    })
  },
  getSingleRevisionInfo({ commit }, payload) {
    //single revision info
    commit("SET_AXIOS_LOADING", true);
    return new Promise((resolve) => {
      http.get(`/memo/${payload.memoId}/info/${payload.revisionId}`)
        .then((response) => {
          if (response && (response.status == 200 || response.status == 'success')) {
            commit('SET_SINGLE_REVISION_INFO', response.data);
          }
          commit("SET_AXIOS_LOADING", false);
          return resolve(response.data);
        })
    })
  },

  setDraftInfo({ commit }, payload) {
    commit('SET_SINGLE_DRAFT_INFO', payload);
  },
  setMemoToReply({ commit }, payload) {
    commit('SET_MEMO_TO_REPLY', payload);
  },
  setDraftHistory({ commit }, payload) {
    commit('SET_SINGLE_DRAFT_HISTORY', payload);
  },
  setDraftLog({ commit }, payload) {
    commit('SET_SINGLE_DRAFT_LOG', payload);
  },
  emptyDraft({ commit }, payload) {
    commit('SET_EMPTY_DRAFT', payload)
  },
  emptyRevision({ commit }, payload) {
    commit('SET_EMPTY_REVISION', payload)
  },
  setSelectAllDraft({ commit }, payload) {
    commit('SET_SELECT_ALL_DRAFT', payload)
  },
  setEditGeneralMemo({ commit }, payload) {
    commit('SET_EDIT_GENERAL_MEMO', payload);
  },
  setEditPermitMemo({ commit }, payload) {
    commit('SET_EDIT_PERMIT_MEMO', payload);
  },
  setSingleDraft({ commit }, payload) {
    commit('SET_SINGLE_DRAFT', payload);
  },
  setSingleRevision({ commit }, payload) {
    commit('SET_SINGLE_REVISION', payload);
  },
  setSingleRevisionInfo({ commit }, payload) {
    commit("SET_SINGLE_REVISION_INFO", payload);
  },
  setFilterParams({ commit }, payload) {
    commit("SET_FILTER_PARAMS", payload);
  },
  setAxiosLoading({ commit }, payload) {
    commit("SET_AXIOS_LOADING", payload);
  },
  setMemoSigning({ commit }, payload) {
    commit("SET_MEMO_SIGNING", payload);
  },
  setDraftIsSaved({ commit }, payload) {
    commit("SET_DRAFT_IS_SAVED", payload);
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}