export const defaultLocale = "id";
export const defaultTheme = 'light';


export const appName = "ELEMEN";
export const shortCompanyName = "Elektronik Memo Nusantara";
export const appVersion = "2.0.0";
export const companyName = "PT Perkebunan Nusantara IV";
export const testingPurpose = false;

export const baseUrl = "https://elemen.palmco.id/service"; //dev only
// export const baseUrl = "https://elemen4.ptpn4.co.id/service"; //production
export const echoUrl = 'https://elemen.palmco.id:8893'; //prod
export const apiUrl = `${baseUrl}/api`;
//export const echoUrl = window.location.hostname +':6001'; //dev
export const echoAppKey = 'haNkCLVr99UMTTqY731rsAwr3ST9HMXJ';
export const echoAppId = 'fZjI86UcC01w9fqq';

export const vapidPublicKey = 'BKH29m5gE4bublNJVxXJ8z7TY0offMLmIS3M94kw0EszCU8zllXVK2V8Pnq1efJ1wPLrRXlwLPQmzxE2Dv-xgn4';

export const rowPerPage = 50;
export const isAuthGuardActive = true;
export const isIntroActive = false;
export const sendDeviceLocation = false;

export const localeOptions = [
  { id: "id", name: "Indonesia" },
  { id: "en", name: "English" }
];


export const notification = {
  title: `${appName}`,
  options: {
    body: '🔔 Permintaan Revisi Sejawat\n✉️ : Ini Adalah Contoh Hal Memo\n🧑🏻 ‍: Vadila Winanda.\nSilahkan ke laman web untuk selengkapnya.',
    vibrate: [500, 110, 500, 110, 450, 110, 200, 110, 170, 40, 450, 110, 200, 110, 170, 40, 500],
    // image: `${process.env.BASE_URL}assets/img/background/notification_bg.jpg`,
    icon: `${process.env.BASE_URL}assets/img/logo.png`,
    requireInteraction: true,
  },

  events: {
    onerror: function () {
      console.error('Custom error event was called');
    },
    onclick: function () {
      // event.preventDefault(); // prevent the browser from focusing the Notification's tab
      window.open('https://elemen4.ptpn4.co.id/memo/draft', '_blank');
      // console.log(event);
      // if (event.action === 'coffee-action') {
      //   // Archive action was clicked
      //   console.log('kopi');
      // } else {
      //   // Main body of notification was clicked
      //   console.log('donat');
      // }
    },
    onclose: function () {
      console.log('Custom close event was called');
    },
    onshow: function () {
      console.log('show it');
    }
  },
};
